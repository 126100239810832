@use 'styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';
@use 'styles/globals/breakpoints';



.item{
    padding: 1.5rem 1.5rem 0 1.5rem;
    line-height: 1;
    display: flex;
    width: 100%;
    flex: 1 0 auto;
    order: 2;

    @media screen and (max-width: breakpoints.$screen-md){
        background-color: #fff;
        padding: 0 0 0.5rem 0;
    }
}


.listLink{
    flex: 1 0 auto;
    color: inherit;
    line-height: 1;
    width: 100%;
    display: flex;
    @include font.dynamic-font(14, 18);
    line-height: 1.2;

    @media screen and (max-width: breakpoints.$screen-md){
        background-color: #f0f0f0;
        padding: 1.5rem 2rem;
    }

    &:hover{
        text-decoration: underline;
    }
}


.subLink{
    &[title*='Shop All']{
        font-style: italic;
    }

    @media screen and (max-width: breakpoints.$screen-md){
        background-color: var(--mist);
        color: #000;

        &[title*='Shop All']{
            font-style: normal;
        }
    }
}


