@use 'styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';
@use 'styles/globals/breakpoints';


.block{
    width: 100vw;
    display: flex;
    left: 0vw;
    z-index: 9999;
    background-color: #f6f6f6;
    min-height: 300px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
    margin: 0;
    padding: 0;
   
    display: flex;
    width: 100vw;
    flex: 1 0 auto;
    position: relative;
    top: 0;
    height: auto;

}

.collapse{
    flex: 0 0 0 !important;
}

.smallHeight{
    height: 400px !important;
}


.loader{
    justify-content: center;
    align-content: center;
    align-items: center;
}


.list{
    padding: .5em;
    margin: 0;
    list-style-type: none;
    // flex: 1 0 auto;
    max-width: 20%;
}

.listFlyout{
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1 0 auto;
    margin: 0;
}


.itemCntr{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    order: 3;

    height: 0;
    opacity: 0;
    margin: 0;

    & [class*="Flyout_subLink"]{
        background-color: var(--white);
    }
}

.itemCntrShow{
    height: auto !important;
    opacity: 1 !important;
}

.item{
    line-height: 1;
    display: flex;
    width: 100%;
    flex: 1 0 auto;
    flex-direction: column;
    order: 1;

    background-color: #fff;
    padding: 0 0 0.5rem 0;
}


.listHeading{
    margin: 0;
    font-weight: 800;
    line-height: 1.4;
    color: inherit;
}


.listHeadingFlyout{
    display: flex;
    flex: 1 0 auto;
    margin: 0;
    line-height: 1;
    align-items: center;
    width: 100%;
    font-size: 1.6rem;
    justify-content: space-between;
    text-decoration: none;
    border: none;
    text-align: left;

    &::after{
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M19 11h-6v-6c0-0.6-0.4-1-1-1s-1 0.4-1 1v6h-6c-0.6 0-1 0.4-1 1s0.4 1 1 1h6v6c0 0.6 0.4 1 1 1s1-0.4 1-1v-6h6c0.6 0 1-0.4 1-1s-0.4-1-1-1z'%3E%3C/path%3E%3C/svg%3E");            
        width: 24px;
        height: 24px;
        display: block;
        margin-left: 10px;
    }
}

.goToHeading{
    &::after{
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='icon-navigate_next' viewBox='0 0 24 24'%3E%3Cpath d='M9.984 6l6 6-6 6-1.406-1.406 4.594-4.594-4.594-4.594z'%3E%3C/path%3E%3C/svg%3E") !important;        width: 24px;
        height: 24px;
        display: block;
        margin-left: 10px;
    }
}

.active{
    &::after{
        display: block;
        content: "";
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M18.984 12.984h-13.969v-1.969h13.969v1.969z'%3E%3C/path%3E%3C/svg%3E%0A");
    }
}

.listLink{
    flex: 1 0 auto;
    color: inherit;
    line-height: 1;
    width: 100%;
    display: flex;
    @include font.dynamic-font(14, 18);
    line-height: 1.2;

   
    background-color: #f0f0f0;
    padding: 1.5rem 2rem;
}

.subLink{
    &[title*='Shop All']{
        font-style: italic;
    }

    @media screen and (max-width: breakpoints.$screen-md){
        background-color: var(--mist);
        color: #000;

        &[title*='Shop All']{
            font-style: normal;
        }
    }
}

.feature{
    background-color: #dddddd;
    padding: 1rem 1.5rem;
    order: 10;
    align-self: flex-end;
    height: 100%;
    position: relative;
}

