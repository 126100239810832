@use 'styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';
@use 'styles/globals/breakpoints';


%flatList{
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.block{
    @extend %flatList;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: stretch;
}

.featured{
    @extend %flatList;
    @extend %max-flyout-height;
    transition: var(--easeInOutBack);
    background-color: var(--ice50);
    display: flex;
    flex-direction: column;
    flex: 1 0 20%;
    width: 20%;
}

.main{
    @extend %flatList;
    @extend %max-flyout-height;
    transition: var(--easeInOutBack);
    width: 80%;
    display: flex;
    flex-direction: column;
    flex: 1 0 80%;
}

.default{
    @extend %max-flyout-height;
}

.short{
    @extend %min-flyout-height;
}

.wee{
    @extend %wee-flyout-height;
}

.columns{
    @extend %flatList;
    // height: 75%;
    // max-height: 75%;
    height: 100%;
    display: flex;
    padding: 2rem 0 2rem 2rem;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
}

// .columnCntr{
//     @extend %flatList;
//     column-width: .25rem;
//     column-count: 3;
//     column-gap: 3rem;
//     column-fill: auto;
// }

.column{
    @extend %flatList;
    max-width: 20%;
    width: 20%;
    display: flex;
    padding: 0 3rem 2rem 0;

    @media screen and (min-width: breakpoints.$screen-xl){
        max-width: 20%;
        width: 20%;
    }

    @media screen and (max-width: breakpoints.$screen-lg){
        max-width: 33%;
        width: 33%;
    }
}

.columnList{
    @extend %flatList;
    width: 100%;
    padding-bottom: 3rem;

    & .item{
        line-height: 1.2
    }
}

.columnsListCollapse{
    @media screen and (max-width: breakpoints.$screen-xl){
        &:has(.item){
            & .item{
                display: none;
            }
        }
    }
}


.columnFigure{
    margin-bottom: 1rem !important
}

.blogs{
    @extend %flatList;
    height: 25%;
    display: flex;
    flex-direction: row;
    background-color: var(--ice25);
}

.section{
    @extend %flatList;
    padding: 2rem;
}

.sectionRow{
    @extend %flatList;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.heading{
    @extend %h4;
    margin-bottom: 1rem;
    line-height: 1.2;
    letter-spacing: 0.1rem;
    @include font.dynamic-font(14, 16);
}

.headingWithImage{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.headingLink{
    @extend .headingWithImage;
    color: inherit;
    width: 100%;
}

.headingImage{
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.figure{
    margin: 0;
}

.figureLink{
    color: inherit;
    display: block;
}

.figureRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;

    & .imageTitle{
        margin: 0 !important;
        padding-left: 1rem;
        @include font.dynamic-font(14, 16);
        line-height: 1.3;
    }
}

.figureRowImage{
    width: auto !important;
    height: 40px !important;
    border-radius: 99rem;
}

.lastFigure{
    margin-bottom: 2rem;
}

.image{
    width: 100%;
    height: auto;
    max-height: 100px;
    object-fit: cover;
}

.imageOnly{
    max-height: 75px;
    object-fit: contain;
    object-position: left;
}

.imageTitle{
    margin: .5rem 0 2rem 0;
    line-height: 1;
    @include font.dynamic-font(14, 16);
}

.divider{
    margin: 1rem 0
}

.item{
    width: 100%;
    padding: 0 0 1rem 0;
}

.itemLink{
    display: block;
    color: inherit;
    @include font.dynamic-font(14, 16);
    line-height: 1.2;
}

.blogRow{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 80%;
}

.blogTitleColumn{
    @extend %flatList;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-direction: flex-start;
    padding-left: 1rem;

    & .itemLink{
        line-height: 1.4;
    }
}

.blogFigure{
    width: 20%;
    align-items: center;
    margin-right: 2rem
}

.blogItemRow{
    @extend %flatList;
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.blogItem{
    width: 25%;
    margin: 0 1rem;

    & .itemLink{
        line-height: 1.4;
    }
}
